import React from 'react'
import { Container, Row, Col } from "react-bootstrap"
import { ThemeProvider } from "styled-components";
import Toogle from "../../../lib/components/Toogle"
import SocialMedia from "../../../lib/components/SocialMedia"
import Title from "../../../lib/components/Title"
import { lightTheme, darkTheme, GlobalStyles, useDarkMode } from "../../../lib/helper/theme"

export default () => {

  const [theme, themeToggler] = useDarkMode();
  const themeMode = theme === 'light' ? lightTheme : darkTheme;

  return (
    <ThemeProvider theme={themeMode}>
      <GlobalStyles />
      <Container className="layout">
        <Row className="layout__section">
          <Col md={6}>
            <SocialMedia themeMode={themeMode} />
          </Col>
          <Col md={6}>
            <Toogle theme={theme} toggleTheme={themeToggler} />
          </Col>
        </Row>
        <Row className="layout__section">
          <Col md={12}>
            <Title themeMode={themeMode} />
          </Col>
        </Row>
      </Container>
    </ThemeProvider>
  )
}
