import React from 'react'
import Switch from '@material-ui/core/Switch';

export default ({ theme, toggleTheme }) => {
  const [state, setState] = React.useState({
    dark: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <Switch
      checked={theme === 'dark' && state?.dark}
      onChange={handleChange}
      onClick={toggleTheme}
      theme={theme}
      color="primary"
      name="dark"
      inputProps={{ 'aria-label': 'primary checkbox' }}
    />
  );
};

