import { createGlobalStyle } from "styled-components"
import { useEffect, useState } from 'react';

const mediaQuery = window.matchMedia('(max-width: 769px)')

export const lightTheme = {
  body: mediaQuery?.matches ? `${"url('/images/light-mobile-bg.jpg')"}` : `${"url('/images/light-bg.jpg')"}`,
  text: '#14213D',
  toggleBorder: '#E5E5E5',
}

export const darkTheme = {
  body: mediaQuery?.matches ? `${"url('/images/dark-mobile-bg.jpg')"}` : `${"url('/images/dark-bg.jpg')"}`,
  text: '#E5E5E5',
  toggleBorder: '#3C6E71',
}

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: all 0.50s linear;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
  }
  `

export const useDarkMode = () => {
  const [theme, setTheme] = useState('dark');

  const setMode = mode => {
    window.localStorage.setItem('theme', mode)
    setTheme(mode)
  };

  const themeToggler = () => {
    theme === 'dark' ? setMode('light') : setMode('dark')
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem('theme');
    localTheme && setTheme(localTheme)
  }, []);

  return [theme, themeToggler]
  
};
