import React from 'react';
import { BrowserRouter as Router, Route, withRouter, Switch } from 'react-router-dom';
import Home from './modules/portfolio/components/Home'

function App() {
  return (
    <Router>
      <Scroller />
        <Switch>
          <Route exact={true} path="/" component={Home} />
        </Switch>
    </Router>
  );
}

export default App;

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}
const Scroller = withRouter(ScrollToTop);