import React from 'react'

export default ({ themeMode }) => {
  return (
    <div className="social_media">
      <a href="https://github.com/Dixit-Appear" rel="noopener noreferrer" target="_blank">
        <i class="fab fa-github" style={{ color: `${themeMode?.text}` }} />
      </a>
      <a href="https://gitlab.com/Edoardo42" rel="noopener noreferrer" target="_blank">
        <i class="fab fa-gitlab" style={{ color: `${themeMode?.text}` }} />
      </a>
      <a href="https://www.instagram.com/diddo6/" rel="noopener noreferrer" target="_blank">
        <i class="fab fa-instagram" style={{ color: `${themeMode?.text}` }} />
      </a>
      <a href="mailto:diddo.42@hotmail.it" rel="noopener noreferrer" target="_blank">
        <i class="fas fa-envelope-open" style={{ color: `${themeMode?.text}` }} />
      </a>
      <a href="https://www.linkedin.com/in/edoardo-olivieri/" rel="noopener noreferrer" target="_blank">
        <i class="fab fa-linkedin" style={{ color: `${themeMode?.text}` }} />
      </a>
    </div>
  )
}